import React from "react"
import { graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "../components/layout"
import StyledHero from "../components/styledHero"
import SEO from "../components/seo"
import Title from "../components/title"
import styles from "../css/text.module.css"

const Wiki = ({ data, location }) => {
  return (
    <Layout location={location.pathname}>
      <SEO title="Wiki" />
      <StyledHero img={data.wikiBcg.childImageSharp.fluid} />
      <section className={styles.text}>
        <Title title="Wiki | " subtitle="Wer, Wie, Was" />
        <div className={styles.center}>
          <article>
            <h4>
              Wer sind die Leistungsempfänger – an wen richtet sich das Angebot
              des Programms?
            </h4>
            <p>
              B2B: Leistungsempfänger sind vor allem Unternehmen, die durch die
              Krise bedingt dringenden Unterstützungsbedarf haben.
            </p>
            <p>
              B2G: Leistungsempfänger ist auch die (halb-) öffentliche Hand,
              also Bund, Länder und Kommunen, mit ihren entsprechenden
              Ministerien, Bundes-, Landes- und Kommunalämtern sowie die
              angeschlossenen öffentlichen Einrichtungen, wie Krankenhäuser,
              Betreuungs- und Bildungseinrichtungen.
            </p>
            <p>
              B2NPO: Zum anderen benötigen Vereine, Verbände, gemeinnützige
              Organisationen, Hilfs-Initiativen und Stiftungen Hilfe.
            </p>
            <AniLink fade to="/join" className="btn-primary">
              Mitmachen!
            </AniLink>
          </article>
        </div>
      </section>
      <section className={`${styles.text} ${styles.even}`}>
        <div className={styles.center}>
          <article>
            <h4>Beitrag der Anbieter – wer kann wie helfen?</h4>
            <p>
              Wir sehen zunächst den Fokus auf Professional Services
              Dienstleistungen. Daher sprechen wir vor allem
              Dienstleistungsunternehmen und Freiberufliche im Bereich IT und
              System Integration, Shared Services, Managed Services, Call
              Center, Finanzmanagement, Wirtschaftsprüfung, Steuerberatung,
              Rechtsberatung und Managementberatung an.
            </p>
            <p>
              Wenn Sie bereit sind, frei gewordene und gleichzeitig
              hochqualifizierte Kapazitäten zur Verfügung zu stellen, freuen wir
              uns auf Ihr Angebot.
            </p>
            <p>
              Wir schließen gleichsam niemanden aus, der keine Professional
              Services Dienstleistungen anbietet, aber einen Beitrag leisten
              möchte. Wir werden alles tun, um eine Lösung zu finden, Ihren
              Beitrag richtig und zielgerichtet einzubringen.
            </p>
            <AniLink fade to="/join" className="btn-primary">
              Mitmachen!
            </AniLink>
          </article>
        </div>
      </section>
      <section className={styles.text}>
        <div className={styles.center}>
          <article>
            <h4>
              Wer kann außerdem einen Betrag leisten, ohne Anbieter oder
              Nachfrager zu sein?
            </h4>
            <p>Wir suchen zur Unterstützung von VsCovidUnited:</p>
            <ul>
              <li>
                Botschafter und Mentoren: Bringen Sie Ihren Namen und Ihre
                Position als Botschafter und Mentor der Plattform ein, indem sie
                z.B. Türen öffnen, weitere Zugänge ermöglichen, die Plattform
                öffentlich promoten, etc.
              </li>
              <li>
                Sponsoren: Monetäre Beiträge sind höchst willkommen. Leisten Sie
                einen Beitrag beim Fundraising, um die Leistungsanbieter bei der
                Selbstkostendeckung zu unterstützen und damit die Wirtschaft zu
                stützen, oder dediziert für einzelne Projekte. Wir als
                Plattformbetreiber garantieren für maximale Transparenz und
                Fairness! Darüber hinaus prüfen wir die organisatorischen sowie
                finanz- und steuerrechtlichen Möglichkeiten und arbeiten an
                entsprechenden Lösungen.
              </li>
              <li>
                Investoren: Investieren Sie in einen Hilfsfond. Sie unterstützen
                und stützen aktiv die Dienstleister, indem Sie die erbrachten
                Leistungen für Unternehmen bezahlen und im Gegenzug
                Minderheitsanteile an diesem Unternehmen bekommen (zu für alle
                Beteiligten hilfeorientierten und fairen Bedingungen)
              </li>
              <li>
                Mitarbeiter bei VsCovidUnited: Wenn Sie unsere Initiative und
                ihre Plattform selbst mit Tat- und Arbeitskraft unterstützen
                wollen, z.B. bei der Koordination und Organisation, bei der
                Öffentlichkeitsarbeit, bei der Suche nach Anbietern auf nicht
                abgedeckte Hilfsbedarfe oder im Back-Office, binden wir Sie
                gerne in unsere Virtuelle Organisation mit ein.
              </li>
            </ul>
            <AniLink fade to="/join" className="btn-primary">
              Mitmachen!
            </AniLink>
          </article>
        </div>
      </section>
      <section className={`${styles.text} ${styles.even}`}>
        <div className={styles.center}>
          <article>
            <h4>
              Welche Projekte sollen durch die Plattform ermöglicht werden?
            </h4>
            <p>Als Projektbeispiele seien genannt:</p>
            <ul>
              <li>
                Entwicklung von RPA (Robotic Process Automation) Lösungen und
                Managed Services zur automatisierten Bearbeitung von
                Massenanträgen der öffentlichen Hand und bei den Banken
              </li>
              <li>Übernahme von Call-Center Kapazitäten</li>
              <li>
                Implementierung und Betrieb von Lösungen und Services zur
                Unterstützung von Home-Office Arbeit oder häuslichen
                Lernumgebungen
              </li>
              <li>Bereitstellung von Online Lernportalen und Lernsoftware</li>
              <li>
                Service Lösungen für Online- und Tele-Learning und Coaching
              </li>
              <li>Unterstützungsleistungen bei rechtlichen Fragen</li>
              <li>
                Unterstützungsleistungen bei Finanzmanagement, Sanierung,
                Krisenmanagement oder drohender Insolvenz
              </li>
              <li>
                Personelle Unterstützung in kritischen Unternehmensfunktionen
              </li>
              <li>
                Hilfe bei der Koordination der kurzzeitigen Auslagerung von
                Unternehmensfunktionen (z.B. durch Quarantäne verursacht)
              </li>
              <li>
                Interims Management von Unternehmensfunktionen oder von
                Einzelprozessen, die durch Personalengpässe nicht fortgeführt
                werden können
              </li>
              <li>
                Bereitstellung einer Onlineplattform (HW, SW, Data Analytics,
                Workflows) für rechtssichere beschleunigte Vergabeverfahren
              </li>
              <li>
                Aufbau und Implementierung von Lösungen zur Sicherstellung einer
                nahtlosen Versorgung und Sicherung der kritischen Infrastruktur
                (Analyseverfahren, Monitoringverfahren, etc.)
              </li>
            </ul>
            <p>
              Über die Plattform werden weitere „Use Cases“ entwickelt und
              erfolgreich durchgeführte Projekte als Blaupausen dargestellt.
            </p>
            <AniLink fade to="/join" className="btn-primary">
              Mitmachen!
            </AniLink>
          </article>
        </div>
      </section>
      <section className={styles.text}>
        <Title title="Wie funktioniert" subtitle="die Plattform" />
        <div className={styles.center}>
          <article>
            <p>
              Die VsCovidUnited Plattform funktioniert wie ein Marktplatz, eine
              Drehscheibe, zur gemeinsamen Bewältigung der Krisensituation. Im
              Einzelnen:
            </p>
            <h4>
              Erfassung und Spezifikation von Hilfegesuchen und Hilfeangeboten
            </h4>
            <p>
              Zur Erfassung von Hilfegesuchen und Hilfeangeboten steht ein
              einfaches Online-Formular zur Verfügung.
            </p>
            <p>
              Wenn Sie die Initiative #VsCovidUnited unterstützen wollen,
              dringend Hilfe benötigen oder Hilfe anbieten wollen, dann melden
              Sie sich bei uns möglichst schnell und spezifisch an, vor allem
              mit folgenden Angaben:
            </p>
            <ul>
              <li>Geben Sie Name/Firmenname/Name der Institution an</li>
              <li>
                Benennen Sie einen Ansprechpartner und die vollständigen
                Kontaktdaten an (Adresse, Mailadresse, Mobilnummer)
              </li>
              <li>
                Wählen Sie Ihre Rolle aus: Hilfesuchender (Nachfrager),
                Anbieter, Botschafter/Mentor, Sponsor, Investor, Mitarbeiter
              </li>
              <li>
                Wenn Sie Anbieter sind, legen Sie sich für ein zielgerichtetes
                Matching bitte fest (hieraus entsteht keine Bewertung,
                Bevorzugung oder Benachteiligung vorab):
              </li>
              <ul>
                <li>
                  Mein Angebot gemäß Spezifikation ist pro bono und ich
                  verzichte freiwillig auf jedwede finanzielle Zuwendung oder
                  Kompensation
                </li>
                <li>
                  Mein Angebot gemäß Spezifikation ist pro bono, ich nehme aber
                  gerne eine eventuelle Zuwendung aus Spenden und Sponsoring
                  entgegen
                </li>
                <li>Ich kann meine Leistung nur mit Vergütung anbieten</li>
              </ul>
              <li>
                Wenn Sie Nachfrager sind, legen Sie sich für ein zielgerichtetes
                Matching bitte fest (hieraus entsteht keine Bewertung,
                Bevorzugung oder Benachteiligung vorab):
              </li>
              <ul>
                <li>
                  Mein Hilfegesuch gemäß Spezifikation ist ausschließlich pro
                  bono
                </li>
                <li>Ich kann die Leistungen zu meinem Hilfegesuch vergüten</li>
              </ul>
              <li>Spezifikationen:</li>
              <ul>
                <li>
                  Was ist genau der Bedarf oder das Angebot, was ist die
                  benötigte oder angebotene Leistung?
                </li>
                <li>
                  Welche Kapazitäten quantitativ (Anzahl Personen, Anzahl
                  Leistungseinheiten) und qualitativ (spezielle Qualifikationen,
                  Seniorität von Personen, Mindestleistungsmerkmale/SLAs) werden
                  benötigt oder angeboten?
                </li>
                <li>
                  Gibt es zeitliche Restriktionen (erst ab oder längstens bis zu
                  einem bestimmten Datum)?
                </li>
                <li>
                  Was ist ansonsten wichtig zu wissen oder zu beachten aus Ihrer
                  Sicht (z.B. im Falle eines Nachfragers: welcher Stunden- oder
                  Tagessatz kann bezahlt werden)?
                </li>
              </ul>
            </ul>
            <p>
              Alle weiteren Anfragen, Anregungen, Fragen können Sie gerne auch
              an den Gruppenpostkorb der Initiative richten:
              <a
                href="mailto:mail@vscovidunited.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                {""} mail@vscovidunited.com
              </a>
            </p>
            <AniLink fade to="/join" className="btn-primary">
              Zur Anmeldung
            </AniLink>
          </article>
        </div>
      </section>
      <section className={`${styles.text} ${styles.even}`}>
        <div className={styles.center}>
          <article>
            <h4>Systematische Zusammenführung von Angebot und Nachfrage</h4>
            <p>
              Die Zusammenführung Hilfeangeboten und Hilfenachfragen und der
              entsprechende Abgleich (Matching) erfolgt auf der
              Matching-Plattform Knooing. Knooing stellt als Partnerunternehmen
              von VsCovidUnited seine Plattform der Initiative für die Laufzeit
              und im Rahmen der Initiative kostenlos zur Verfügung. Anbieter und
              Nachfrager profitieren vom Matching-Algorithmus der Knooing
              Plattform und den sich daraus ergebenden Ergebnissen. Dazu
              folgende wichtigen Hinweise:
            </p>
            <ul>
              <li>
                Sobald Sie das Formular als Hilfesuchender (Nachfrager) oder
                Hilfeanbieter bei VsCovidUnited ausgefüllt haben, wird Ihr
                Anliegen von uns geprüft.
              </li>
              <li>
                Nach einer erfolgreichen Prüfung erhalten Sie von VsCovidUnited
                eine E-Mail mit einem Link zur Registrierung bei Knooing, einem
                Identifier und weiteren Instruktionen.
              </li>
              <li>
                Bei Knooing registrieren Sie sich bitte und geben im Textfeld
                den Identifier ein. Damit ist sichergestellt, dass sie das sonst
                zahlungspflichtige Angebot von Knooing frei nutzen können.
              </li>
              <li>
                VsCovidUnited greift nicht weiter in den Matchingmechanismus von
                Knooing ein. Sofern sich Angebot und Nachfrage auf der Knooing
                Plattform finden, werden Sie entsprechend durch Knooing
                informiert.
              </li>
              <li>
                Wenn es zwischen Nachfrager und Anbieter zu bilateralen
                Übereinkünften kommt, bedeutet das zunächst einen Erfolg unserer
                Initiativbemühungen. Diese Übereinkunft wird in Knooing
                registriert und an Sie übermittelt. VsCovitUnited wird nur
                anonymisierte Auswertungen darüber erhalten.
              </li>
            </ul>
            <AniLink fade to="/join" className="btn-primary">
              Mitmachen!
            </AniLink>
          </article>
        </div>
      </section>
      <section className={styles.text}>
        <div className={styles.center}>
          <article>
            <h4>
              Bündelung verschiedener Hilfsangebote für dediziert nachgefragte
              Projekte oder zur Herstellung relevanter interdisziplinärer
              Serviceangebote sowie aktive Suche nach Anbietern und Nachfragern,
              wo sich Angebot und Nachfrage nicht decken
            </h4>
            <p>
              Mit der Zielsetzung, möglichst viele Angebote und Nachfragen
              zusammenzuführen, werden die VsCovidUnited Partnerunternehmen
              Beceptum und ISG die Matchingergebnisse fortlaufend monitoren und
              analysieren. Damit wollen wir zu folgenden Erkenntnissen kommen:
            </p>
            <ul>
              <li>Geben Sie Name/Firmenname/Name der Institution an</li>
              <li>Welche Hilfeangebote finden keinen Abnehmer und warum?</li>
              <li>
                Welche Hilfegesuche können nicht bedient werden und warum?
              </li>
            </ul>
            <p>
              Mit den erlangten Erkenntnissen wollen wir aktiv nachsteuern, mit
              u.a. folgenden Maßnahmen:
            </p>
            <ul>
              <li>
                Aktive Bewerbung von nicht angenommenen Angeboten und nicht
                bedienten Nachfragen (dies erfolgt abstrakt, ohne Benennung von
                Firmen, Institutionen und Personen!)
              </li>
              <li>
                Aktive Suche nach entsprechenden Anbietern und Nachfragern
              </li>
              <li>
                Bündelung verschiedener Hilfsangebote für dediziert nachgefragte
                Projekte
              </li>
              <li>
                Bündelung verschiedener Hilfsangebote zur Herstellung relevanter
                interdisziplinärer Serviceangebote
              </li>
            </ul>
            <p>
              Hiermit wollen wir als VsCovidUnited einen Mehrwert schaffen. Wir
              werden Sie dazu individuell kontaktieren.
            </p>
          </article>
        </div>
      </section>
      <section className={`${styles.text} ${styles.even}`}>
        <div className={styles.center}>
          <article>
            <h4>Umsetzung von finanziellen Stützungsmaßnahmen</h4>
            <p>
              Nun geht es ums wirtschaftliche Überleben. Die Initiative hat sich
              auf die Fahnen geschrieben, Maßnahmen umzusetzen, um Hilfeanbieter
              und Hilfeempfänger auch wirtschaftlich unterstützen zu können. Und
              so funktionieren die Maßnahmen im Einzelnen:
            </p>
            <ul>
              <li>
                Wir suchen aktiv nach budgetierten und finanzierten
                Umsetzungsprojekten
              </li>
              <ul>
                <li>
                  Was macht VsCovidUnited? Wir nutzen unsere Netzwerke, um
                  potentielle Bedarfsträger auf entsprechende Projekte
                  anzusprechen und sie dazu zu bewegen, die jeweiligen Projekte
                  über unsere Plattform zu staffen.
                </li>
                <li>
                  Wie erfolgt die Zuteilung von Hilfeangeboten auf finanzierte
                  Projekte? Die Zuteilung erfolgt zunächst ausschließlich über
                  den Matching-Prozess unseres Plattformpartners Knooing.
                  VsCovidUnited greift dabei NICHT aktiv in die Zuteilung ein.
                  Lediglich für den Fall, dass sich für ein Projekt keine
                  Anbieter finden, wird VsCovidUnited unterstützend eingreifen,
                  um unter maximaler Einbringung der Hilfeanbieter ein
                  relevantes Angebot auf die Beine zu stellen.
                </li>
                <li>
                  Was ist der Fairness Mechanismus? Wir lassen in Bezug auf
                  budgetierte und finanzierte Projekte den Marktkräften und
                  Marktteilnehmern freien Lauf.
                </li>
              </ul>
              <li>
                Wir machen uns für öffentliche Fördermittel für die Initiative
                stark.
              </li>
              <ul>
                <li>
                  Was macht VsCovidUnited? Wir suchen nach kurzfristigen
                  Fördermöglichkeiten der öffentlichen Hand jenseits der
                  firmenindividuellen Hilfspakete.
                </li>
                <li>
                  Wie erfolgt die Umverteilung von Fördermitteln auf
                  Hilfeanbieter? Dies hängt stark vom jeweiligen
                  Förderinstrument ab. Förderungen, die die Initiative selbst
                  bekommt, werden im VsCovidUnited Finanzpool gesammelt.
                  Förderungen, die sich auf bestimmte Projekte beziehen, werden
                  diesen Projekten direkt zugewiesen. Den Regularien des
                  jeweiligen Förderinstruments wir unbedingt und uneingeschränkt
                  gefolgt.
                </li>
                <li>
                  Was ist der Fairness Mechanismus? Zum einen sorgen wir als
                  Initiative für Transparenz und informieren regelmäßig.
                  Geförderte Projekte werden als solche auch gekennzeichnet.
                  Förderungen, die die Initiative selbst bekommt, werden im
                  VsCovidUnited Finanzpool gesammelt, der leistungsgerecht
                  ausgeschüttet wird (siehe unten).
                </li>
              </ul>
              <li>Wir sammeln Spenden und Sponsorengelder</li>
              <ul>
                <li>
                  Was macht VsCovidUnited? Wir suchen aktiv nach Spenden und
                  Sponsorengeldern. Sponsoren bieten wir hierfür die Platzierung
                  des Firmenlogos oder des Namens auf dem VsCovidUnited Portal.
                </li>
                <li>
                  Wie erfolgt die Umverteilung von Spenden und Sponsorengeldern?
                  Spenden und Sponsorengelder werden im VsCovidUnited Finanzpool
                  gesammelt, der leistungsgerecht ausgeschüttet wird.
                </li>
                <li>
                  Was ist der Fairness Mechanismus? Der Finanzpool wird
                  leistungsgerecht ausgeschüttet. Alle Leistungen werden
                  quanitativ erfasst. Das heißt konkret, dass die geleisteten
                  Stunden und die Serviceleistungen durch die Hilfeempfänger
                  dokumentiert werden. Um schnell Hilfe zu leisten, wird sobald
                  ein Pool besteht, ein Sockelbetrag pro Stunde oder
                  Dienstleistung für die erbrachten Leistungen ausgeschüttet.
                  Zum Ende der Initiative wird der Restbetrag des Pools nach
                  diesem Mechnismus verteilt.
                </li>
              </ul>
              <li>Private equity basierter Hilfsfonds</li>
              <ul>
                <li>
                  Was macht VsCovidUnited? In Zusammenarbeit mit Arnd Thorn,
                  Botschafter und Mentor unserer Initiative, werden wir Familiy
                  Offices und Investoren ansprechen, sich am VsCovidUnited
                  private equity Hilfsfond für Unternehmen zu beteiligen. Ziel
                  ist es, mehr Leistungen von Anbietern über VsCovidUnited zu
                  vergüten und gleichzeitig nachfragende Unternehmen zu stützen.
                </li>
                <li>
                  Wie funktioniert der VsCovidUnited Hilfsfond? Unternehmen auf
                  der Nachfragerseite formulieren Ihre Nachfrage an
                  erforderlichen Professional Services Unterstützungsleistungen
                  und stellen eine Abschätzung an zu leistender Arbeitskapazität
                  zur Verfügung. Dieses Arbeits- und Servicevolumen wird zu
                  marktüblichen Konditionen bewertet. Darüber hinaus stellt das
                  Unternehmen Daten und Informationen zur Verfügung, die eine
                  Unternehmensbewertung in einem abgekürzten Schnellverfahren
                  ermöglichen. Wir versuchen, durch ein
                  Bewertungsschnellverfahren, dass von einem Wirtschaftsprüfer
                  begleitet wird, den Prozess zu beschleunigen. Gemäß der zu
                  erwartenden Unterstützungsleistung wird im nächsten Schritt
                  ermittelt, welcher Beteiligungshöhe diese im Gegenzug
                  entspricht. Bei einer Einigung von Investoren bzw. Hilfsfond,
                  Unternehmen und Dienstleistern übernimmt der Hilfsfond die
                  Vergütung der erbrachten Dienstleistungen und erhält im
                  Gegenzug die ermittelten Anteile am leistungsempfangenden
                  Unternehmen.
                </li>
                <li>
                  Wie erfolgt die Zuteilung von Fondgeldern, Unternehmen und
                  Dienstleistern? Hierfür gibt es keinen automatisierten
                  Mechanismus. Zunächst sollten sich Unternehmen, die offen für
                  das Hilfsfond-Konstrukt sind, aktiv bei uns melden. Das
                  Matchmaking des Bedarfs mit den Hilfsangeboten erfolgt
                  standardmäßig über die Knooing-Plattform. Parallel legen wir
                  den Fall den Investoren des Hilfsfonds vor und starten das
                  Bewertungsschnellverfahren. VsCovidUnited koordiniert die
                  Abstimmung und Kommunikation zwischen den Beteiligten.
                </li>
                <li>
                  Was ist der Fairness Mechanismus? Für den VsCovidUnited
                  Hilfsfond gelten Regeln, die der Zielsetzung der Initiative
                  gerecht werden.
                </li>
                <li>
                  Die Unternehmensbeteiligungen dürfen lediglich eine
                  Minderheitsbeteiligung bis maximal 19% des Eigenkapitals sein.
                </li>
                <li>
                  Beteiligungen können auf Basis bilateraler Einigung zwischen
                  Investoren und Unternehmen über die gegenfinanzierte Vergütung
                  der Dienstleistung hinausgehen, die
                  Minderheitsbeteiligungsgrenze bleibt dabei bestehen.
                </li>
                <li>
                  Die Form der Beteiligung ist variabel und basiert auf der
                  gegenseitigen Einigung von Investor und Unternehmen.
                </li>
                <li>
                  Die Beteiligung hat eine grundsätzliche Laufzeit von drei
                  Jahren.
                </li>
                <li>
                  Das Unternehmen kann jederzeit in diesem Zeitraum von drei
                  Jahren bei verbesserter Liquidität die Anteile
                  zurückfordern/ablösen.
                </li>
              </ul>
            </ul>
            <p>
              Alle finanziellen Stützungsmaßnahmen sowie alle dahinterliegenden
              und verbunden Mechanismen und Prozesse werden von einem
              Wirtschaftsprüfer/Steuerberater/Rechtanwalt regulatorisch
              begleitet und überwacht.
            </p>
            <p>
              Garantien von oder finanzielle Ansprüche an die Plattform gibt es
              nicht.
            </p>
          </article>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    wikiBcg: file(relativePath: { eq: "wikiBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Wiki
